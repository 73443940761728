import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from './firebaseConfig';
import Cookies from "js-cookie";
import moment from "moment";
import {
  setPersistence,
  signInWithEmailAndPassword,
  indexedDBLocalPersistence,
} from "firebase/auth";

import {
  Container,
  Modal,
  Title,
  Form,
  ErrorMessage
} from "../styles/login";
import Logo from "../assets/img/logo.png";

function Login() {

  const navigate = useNavigate();
  const [signInEmail, setSignInEmail] = useState('');
  const [signInPassword, setSignInPassword] = useState('');
  const [activeError, setActiveError] = useState(false);
  const formRef = useRef(null);

  const signInWithGoogleAuth = () => {
    setPersistence(auth, indexedDBLocalPersistence)
      .then(() => {
        return signInWithEmailAndPassword(auth, signInEmail, signInPassword);
      }).then(() => {

        Cookies.set("@innovally", JSON.stringify(
          {
            email: auth.currentUser.email,
            token: auth.currentUser.accessToken,
          }),
          { expires: moment().utc(true).add(24, "hours").toDate() }
        );

        navigate("/")
      })
      .catch((error) => {
        setActiveError(true);
      });
  }

  return (
    <Container>
      <Modal>
        <img src={Logo} alt="logo Inovally" />
        <Title>Acesse sua conta</Title>
        {activeError && <ErrorMessage>
          <div className="icon">
            <svg height="16" width="16" role="img" aria-label="Warning Icon" viewBox="0 0 16 16">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.8639 1.51357C8.49039 0.828811 7.50961 0.828811 7.1361 1.51357L1.12218 12.5388C0.763263 13.1968 1.23814 14 1.98608 14H14.0139C14.7619 14 15.2367 13.1968 14.8778 12.5388L8.8639 1.51357ZM7 5C7 4.44772 7.44772 4 8 4C8.55228 4 9 4.44772 9 5V9C9 9.55228 8.55228 10 8 10C7.44772 10 7 9.55228 7 9V5ZM9 12C9 12.5523 8.55228 13 8 13C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11C8.55228 11 9 11.4477 9 12Z" fill="currentColor"></path>
            </svg>
          </div>
          <div className="content">Forneça um endereço de e-mail e uma senha válidos.</div>
        </ErrorMessage>}
        <Form ref={formRef}>
          <div>
            <label>Usuário</label>
            <input
              type="text"
              name="email"
              placeholder="Email"
              onChange={(e) => { setSignInEmail(e.target.value); setActiveError(false) }}
            />
          </div>
          <div>
            <label>Senha</label>
            <input
              type="password"
              name="password"
              placeholder="Password"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {signInWithGoogleAuth()}
              }}
              onChange={(e) => { setSignInPassword(e.target.value); setActiveError(false) }}
            />
          </div>
          <div className="forgot">
            <Link to={`/esqueci-minha-senha`}>Esqueceu sua senha?</Link>
          </div>
          <button onClick={() => signInWithGoogleAuth()}>Login na sua conta Inovally</button>
        </Form>
      </Modal>
    </Container>
  );
}

export default Login;

import { useCallback, useEffect, useState } from "react";
import * as S from "./styles";
import axios from "axios";
import { getDebtToken } from "../../services/auth";

const AccessLevels = ({ setParentValue }) => {

  const [dataTable, setDataTable] = useState([{
    name: "",
    color: "",
    permissions: "",
    quantityUsers: "",
    id: "",
  },
  ]);

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(false);

  const search = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_DEBT_API}admin/getUserRoles?countUser=true`, {
        headers: {
          'Authorization': `Bearer ${getDebtToken()}`,
          'Content-Type': 'application/json'
        }
      });
      const data = response.data;
      setDataTable(data);
    } catch (error) {
      console.log(error);
    }
  }, [])

  const editAccessLevel = (id) => {
    localStorage.setItem("levelId", id);
    setParentValue("cadastroNivel");
  }

  const openDeleteConfirmation = (row) => {
    setShowModal(true);
    setModalContent(row);
  }

  const deleteAccessLevel = useCallback(async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_DEBT_API}admin/deleteUserRole/${modalContent.id}`, {
        headers: {
          'Authorization': `Bearer ${getDebtToken()}`,
          'Content-Type': 'application/json'
        }
      });
      
      alert("Nível de acesso deletado com sucesso!");
      setShowModal(false);
      search();
    } catch (error) {
      alert("Erro ao deletar, contate um administrador.");
      console.log(error);
    }
  }, [modalContent, search]);

  useEffect(() => {
    search();
  }, [search])

  return (
    <>
      <S.Header>
        <S.Content>
          <S.Breadcrumb><button onClick={() => setParentValue("nivel")}>Home</button> <span>&gt;</span> <span>Nível de acesso</span> <span>&gt;</span></S.Breadcrumb>
          <S.Title>Listagem</S.Title>
        </S.Content>
        <S.Actions>
          <S.AddUser onClick={() => { setParentValue("cadastroNivel") }}><span>+</span>Adicionar Nível</S.AddUser>
        </S.Actions>
      </S.Header>
      <S.Card>
        <S.TableHead>
          <span className="row-4">Nome</span>
          <span className="row-3">Cor</span>
          <span className="row-2">Usuários</span>
          <span className="row-2"></span>
        </S.TableHead>
        <S.DataTable>
          {dataTable.length > 0 && dataTable.map((row, index) => {
            return <S.Line key={index} id={row.id}>
              <span className="row-4">{row.name}</span>
              <span className="row-3"><S.Circle $bgcolor={row.color}></S.Circle>{row.color}</span>
              <span className="row-2">{row.quantityUsers}</span>
              <span className="row-2">
                <S.LineActions>
                  <S.EditBtn onClick={() => editAccessLevel(row.id)}><img src="./edit.svg" alt="Editar"></img></S.EditBtn>
                  <S.DeleteBtn onClick={() => openDeleteConfirmation(row)}><img src="./delete.svg" alt="Deletar"></img></S.DeleteBtn>
                </S.LineActions>
              </span>
            </S.Line>
          })}
        </S.DataTable>
        {(dataTable.length === 0) && <div>Não existem usuários.</div>}
      </S.Card >
      {showModal && <S.Container>
        <S.Modal>
          <S.ModalTitle>Deseja continuar?</S.ModalTitle>
          <S.ModalContent id="content">
            <div>
              <p>Você está excluindo o nível de acesso:</p>
              {modalContent && <h5>{modalContent.name}</h5>}
              <button className="excluir" onClick={() => { deleteAccessLevel() ; setShowModal(false); }}>Excluir</button>
              <button onClick={() => { setShowModal(false); }}>Cancelar</button>
            </div>
          </S.ModalContent>
        </S.Modal>
      </S.Container>}
    </>

  )
}

export default AccessLevels;
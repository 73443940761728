import { useEffect, useState } from "react";
import * as S from "./styles";
import axios from "axios";
import { getDebtToken } from "../../services/auth";

const Listing = ({ setParentValue }) => {
  const [page, setPage] = useState(0);
  const [dataTable, setDataTable] = useState([]);
  const [filter, setFilter] = useState({ name: ""});
  const [totalUsers, setTotalUsers] = useState(0);
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const search = async (pageToLoad = 0, isPagination = false) => {
    try {
      let response;
      if (filter.name) {
        response = await axios.get(
          `${process.env.REACT_APP_DEBT_API}user/filter?name=${filter.name}`,
          {
            headers: {
              'Authorization': `Bearer ${getDebtToken()}`,
              'Content-Type': 'application/json'
            }
          }
        );
      } else {
        response = await axios.get(
          `${process.env.REACT_APP_DEBT_API}user/listPageable?page=${pageToLoad}`,
          {
            headers: {
              'Authorization': `Bearer ${getDebtToken()}`,
              'Content-Type': 'application/json'
            }
          }
        );
      }

      const data = response.data.data;
      setDataTable((prev) => isPagination ? [...prev, ...data] : data);
      setTotalUsers(response.data.totalElements);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setPage(0);
    search(0, false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const timeout = setTimeout(() => {
      search(0, false);
    }, 500);

    setDebounceTimeout(timeout);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [filter]);

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    search(nextPage, true);
  };

  const goToEdit = (id) => {
    localStorage.setItem("userId", id);
    setParentValue("cadastro");
  };

  const exportUsers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_DATA_API}user`, {
        headers: {
          'Authorization': `${process.env.REACT_APP_DATA_AUTH}`,
          'token-debt': `${getDebtToken()}`,
          'Content-Type': 'application/csv'
        }
      })
      let blob = response.data;
      const csvContent = new Blob([blob], { type: 'text/csv;charset=utf-8;' });

      const url = window.URL.createObjectURL(csvContent);
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', 'usuarios.csv');

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <S.Header>
        <S.Content>
          <S.Breadcrumb>
            <button onClick={() => setParentValue("listagem")}>Home</button>
            <span>&gt;</span>
            <span>Usuários</span>
            <span>&gt;</span>
          </S.Breadcrumb>
          <S.Title>Listagem</S.Title>
          <S.FoundUsers>
            <span>{totalUsers} usuários cadastrados</span>
          </S.FoundUsers>
        </S.Content>
        <S.Actions>
          <S.AddUser onClick={() => { setParentValue("cadastro") }}>
            <span>+</span>Adicionar Usuário
          </S.AddUser>
          <S.ExportXLSX onClick={() => { exportUsers() }}>
            Exportar usuários
          </S.ExportXLSX>
        </S.Actions>
      </S.Header>
      <S.Card>
        <S.SearchWrapper>
          <S.CardTitle>Buscar usuário</S.CardTitle>
          <div>
            <S.Searchbar
              placeholder="Digite o nome"
              onInput={(e) => { setFilter((prev) => ({ ...prev, name: e.target.value })) }}
            ></S.Searchbar>
          </div>
        </S.SearchWrapper>
        <S.Table>
          <S.TableHead>
            <span className="row-2">Nome</span>
            <span className="row-3">Contato</span>
            <span className="row-2">Prefeitura</span>
            <span className="row-1">Cargo/Função</span>
            <span className="row-1">Perfil</span>
            <span className="row-2">Nível de acesso</span>
            <span className="row-1">Ativo?</span>
          </S.TableHead>
          <S.DataTable>
            {dataTable.length > 0 && dataTable.map((row, index) => {
              return (
                <S.Line key={index} className={row?.disabled ? "disabled" : ""} onClick={() => { goToEdit(row.id) }}>
                  <span className="row-2">
                    <b>{row?.name}</b> {row?.cpf ? <><br /><small>{row?.cpf}</small></> : ''}
                  </span>
                  <span className="row-3">
                    {row?.email} {row?.phoneNumber ? <><br /><small>{row?.phoneNumber}</small></> : ''}
                  </span>
                  <span className="row-2">
                    {row?.cityHall?.legalName}
                  </span>
                  <span className="row-1">
                    {row?.position} {row?.sector ? <><br /><small>{row?.sector}</small></> : ''}
                  </span>
                  <span className="row-1">Usuário{row.projectInspector ? ", Fiscal" : ""}</span>
                  <span className="row-2">
                    <S.Coloured $colour={row?.role?.color}>{row?.role?.name}</S.Coloured>
                  </span>
                  <span className="row-1">
                    {!row?.disabled ? <span style={{ color: "green", textTransform: "uppercase" }}>Sim</span> : <span style={{ color: "red", textTransform: "uppercase" }}>Não</span>}
                  </span>
                </S.Line>
              );
            })}
            {dataTable.length > 0 && dataTable.length < totalUsers && <S.BtnSearch onClick={loadMore}>Carregar mais +</S.BtnSearch>}
          </S.DataTable>
          {(dataTable.length === 0) && <S.Error><h3>Não existem usuários.</h3></S.Error>}
        </S.Table>
      </S.Card>
    </>
  );
}

export default Listing;
import { useCallback, useEffect, useState } from "react";
import * as S from "./styles";
import axios from "axios";
import { getDebtToken } from "../../services/auth";

const AddLevels = ({ setParentValue }) => {

  const [permissions, setPermissions] = useState("");
  const [idData, setIdData] = useState("");

  const [data, setData] = useState({
    name: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value
    });
  };

  const getIdData = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_DEBT_API}admin/getUserRole/${idData}`, {
        headers: {
          'Authorization': `Bearer ${getDebtToken()}`,
          'Content-Type': 'application/json'
        }
      });
      const data = response.data;
      setData({ name: data?.name });
      setColor(data?.color);
      setSelectedPermissions(data?.permissions);

    } catch (error) {
      console.log(error);
    }
  }, [idData])

  const [color, setColor] = useState("#000000");

  const search = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_DEBT_API}admin/getPermissions`, {
        headers: {
          'Authorization': `Bearer ${getDebtToken()}`,
          'Content-Type': 'application/json'
        }
      });
      const data = response.data;
      setPermissions(data);
    } catch (error) {
      console.log(error);
    }
  }, [])

  const checkIsValidInput = (element) => {
    if (element.name === "name") {
      let valid = element.value.length >= 1;
      valid ? element.parentElement.classList.remove("error") : element.parentElement.classList.add("error");
    }
  };

  const getSelectedPermissions = () => {
    let selected = [];
    let lines = document.querySelectorAll("#line");
    let arrLines = Array.from(lines);

    arrLines.forEach((element) => {
      if (element.children[1].firstChild.firstChild.checked) {
        selected.push(element.children[0].id);
      }
    });
    return selected;
  }

  const setSelectedPermissions = (array) => {
    let lines = document.querySelectorAll("#line");
    let arrLines = Array.from(lines);

    arrLines.forEach((element) => {
      let idLine = element.firstChild.id;
      let checkbox = element.children[1].firstChild.firstChild;
      if (array.includes(idLine)) {
        checkbox.checked = true;
      } else {
        checkbox.checked = false;
      }
    });
  }

  const addPermissions = useCallback(async () => {
    let selectedPermissions = getSelectedPermissions();
    const addData =
    {
      "id": null,
      "name": data.name,
      "color": color,
      "permissions": selectedPermissions,
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_DEBT_API}admin/saveUserRole`, addData, {
        headers: {
          'Authorization': `Bearer ${getDebtToken()}`,
          'Content-Type': 'application/json'
        }
      });
      if (response.status === 201) {
        alert("Criado com sucesso!");
        setParentValue("nivel");
      }
    } catch (error) {
      console.log(error);
    }

  }, [data, color, setParentValue])

  const editPermissions = useCallback(async () => {
    let selectedPermissions = getSelectedPermissions();
    const editData =
    {
      "id": idData,
      "name": data.name,
      "color": color,
      "permissions": selectedPermissions,
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_DEBT_API}admin/saveUserRole`, editData, {
        headers: {
          'Authorization': `Bearer ${getDebtToken()}`,
          'Content-Type': 'application/json'
        }
      });
      if (response.status === 200) {
        alert("Editado com sucesso!");
        setParentValue("nivel");
      }
    } catch (error) {
      console.log(error);
    }

  }, [data, color, idData, setParentValue])

  useEffect(() => {
    search();
  }, [search])

  useEffect(() => {
    let levelId = localStorage.getItem("levelId");
    if (levelId) setIdData(levelId);
    localStorage.removeItem("levelId");
    if (idData) {
      getIdData();
    }
  }, [getIdData, idData])


  return (
    <>
      <S.Header>
        <S.Content>
          <S.Breadcrumb><button onClick={() => setParentValue("nivel")}>Home</button> <span>&gt;</span> <span>Nível de acesso</span> <span>&gt;</span></S.Breadcrumb>
          <S.Title>Nível de acesso</S.Title>
        </S.Content>
      </S.Header>
      <S.Card>
        {!idData && <S.CardTitle>Criar acesso</S.CardTitle>}
        {idData && <S.CardTitle>Editar acesso</S.CardTitle>}
        <form>
          <div className="row-8">
            <label>
              Nome *
              <input type="text"
                name="name"
                maxLength={100}
                required
                value={data.name}
                onChange={handleChange}
                onBlur={(e) => { checkIsValidInput(e.target) }}></input>
              <small>Nome é obrigatório</small>
            </label>
          </div>
          <div className="row-4">
            <label>
              Escolha uma cor
            </label>
            <input
              type="color"
              value={color}
              onChange={(e) => setColor(e.target.value)}
            ></input>
          </div>

          <h3>InovaDEBT</h3>
          <S.Table>
            <S.TableHead>
              <span className="row-9">Áreas</span>
              <span className="row-3">Ativar</span>
            </S.TableHead>
            <S.DataTable>
              {permissions.length > 0 && permissions.map((row, index) => {
                return <S.Line key={index} id="line">
                  <p className="row-9" id={row?.id}>{row?.name}</p>
                  <div className="row-3">
                    <label className="switch" htmlFor={row?.name}>
                      <input id={row?.name} type="checkbox"></input><div className="slider round"></div>
                    </label>
                  </div>
                </S.Line>
              })}
            </S.DataTable>
            {(permissions.length === 0) && <S.Error><h3>Não existem permissões.</h3></S.Error>}
          </S.Table>
        </form>
        <S.Actions className="row-12">
          <S.BtnCancel onClick={() => { setParentValue("nivel") }}>Cancelar</S.BtnCancel>
          {!idData && <S.BtnSave onClick={() => { addPermissions() }}>Salvar</S.BtnSave>}
          {idData && <S.BtnSave onClick={() => { editPermissions() }}>Editar</S.BtnSave>}
        </S.Actions>
      </S.Card>
    </>

  )
}

export default AddLevels;
import styled from "styled-components";

export const Container = styled.div`
  margin-top: 80px;
  max-width: 100vw;
  display: flex;
  background-color: rgb(240, 240, 240);
  min-height: calc(100vh - 80px);
`;

export const Aside = styled.aside`
  transition: all 0.3s;
  position: fixed;
  z-index: 50;
  color: #a1a1a1;
  min-height: 100vh;
  background: linear-gradient(0deg, #14D1AA, #0A5282);
  
  & > ul {
    margin: 0;
    padding: 0;

    & > li {
      font-family: Lato, sans-serif;
      text-decoration: none;
      border-radius: 2px;
      color: #fff;
      align-items: center;
      font-stretch: normal;
      font-size: 15px;
      font-style: normal;
      line-height: 1.43;
      list-style: none;
      letter-spacing: normal;
      cursor: pointer;

      &:hover {
        font-weight: 700;
        color: #35383b;
      }

      &.active {
        background-color: #14D2AA;
        font-weight: 700;
        color: #35383b;
      }
    }

    & > button {
      cursor: pointer;
      width: 100%;
      margin: 16px 0 10px;
      text-decoration: none;
      border-radius: 2px;
      font-family: Lato, sans-serif;
      border: 0;
      padding: 12px 24px;
      display: block;
      font-weight: 700;
      color: #35383b;
      display: flex;
      align-items: center;
      font-stretch: normal;
      font-size: 15px;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      & > img {
        margin-right: 6px;
      }
    }
  }
`;

export const Content = styled.div`
  padding: 16px;
  margin-left: 80px;
  width: calc(100% - 80px);
`;
import React, { useCallback, useEffect, useState } from "react";
import { Container, Aside, Content } from "../styles/setting";
import Listing from "../components/listing/listing";
import Register from "../components/register/register";
import AccessLevels from "../components/accessLevels/accessLevels";
import AddLevels from "../components/addLevels/addLevels";
import CityHall from "../components/cityHall/cityHall";
import Header from "../components/header/header";
import CityHallListing from "../components/cityHallList/cityHallListing";
import { isAuthenticated } from "../services/auth";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

function Settings() {

  const [step, setStep] = useState("listagem");
  const [isAdmin, setIsAdmin] = useState(true);

  const activation = (id) => {
    const list = document.querySelectorAll("ul#list li");
    list.forEach((element) => element.classList.remove("active"));

    const targetItem = document.getElementById(id);
    targetItem.parentElement.classList.add("active");
    setStep(id);
  }

  const navigate = useNavigate();

  const verifyIsAdmin = useCallback(async () => {
    if (Cookies.get("@inovally")) {
      const personalValues = Cookies.get("@inovally");
      if (JSON.parse(personalValues).email !== "cidade_modelo@inovally.com.br" && JSON.parse(personalValues).email !== "test@test.com.br") setIsAdmin(false);
    }
  }, [setIsAdmin]);

  useEffect(() => {
      verifyIsAdmin();
  }, [verifyIsAdmin, isAdmin])

  useEffect(() => {
    if (!isAuthenticated() || !isAdmin) {
      navigate('/');
      return;
    }
  }, [isAdmin, navigate])



  return (
    <>
      <Header></Header>
      <Container>
        <Aside>
          <ul id="list">
            <li className="active" onClick={(e) => { activation(e.target.id) }}><img id="listagem" alt="" src="./users.svg"></img></li>
            <li onClick={(e) => { activation(e.target.id) }}><img alt="" id="prefeitura" src="./city-hall.svg"></img></li>
            <li onClick={(e) => { activation(e.target.id) }}><img alt="" id="nivel" src="./access-levels.svg"></img></li>
          </ul>
        </Aside>
        <Content>
          {step === "listagem" && <Listing setParentValue={setStep} />}
          {step === "cadastro" && <Register setParentValue={setStep} />}
          {step === "nivel" && <AccessLevels setParentValue={setStep} />}
          {step === "cadastroNivel" && <AddLevels setParentValue={setStep} />}
          {step === "prefeitura" && <CityHallListing setParentValue={setStep} />}
          {step === "cadastroPrefeitura" && <CityHall setParentValue={setStep} />}
        </Content>
      </Container>
    </>
  );
}

export default Settings;

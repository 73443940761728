export function mascararCPF(element) {
if(!element.value) return;
if(element.value.length === 0) return;
  let valor = element.value.replace(/\D/g, "");
  valor = valor.replace(/^(\d{3})(\d)/, "$1.$2");
  valor = valor.replace(/^(\d{3})\.(\d{3})(\d)/, "$1.$2.$3");
  valor = valor.replace(/^(\d{3})\.(\d{3})\.(\d{3})(\d)/, "$1.$2.$3-$4");
  element.value = valor;
}

export function mascararCNPJ(element) {
  if(!element.value) return;
  if(element.value.length === 0) return;
  let valor = element.value.replace(/\D/g, "");
  valor = valor.replace(/^(\d{2})(\d)/, "$1.$2");
  valor = valor.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
  valor = valor.replace(/\.(\d{3})(\d)/, ".$1/$2");
  valor = valor.replace(/(\d{4})(\d)/, "$1-$2");
  element.value = valor;
}
export function mascararCEP(element) {
  if(!element.value) return;
  if(element.value.length === 0) return;
  let valor = element.value.replace(/\D/g, "");
  valor = valor.replace(/^(\d{5})(\d)/, "$1-$2");
  element.value = valor;
}
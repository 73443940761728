import styled from "styled-components";

export const Title = styled.h1`
box-sizing: border-box;
color: rgb(8, 11, 14);
display: block;
font-size: 24px;
font-weight: 400;
letter-spacing: 0.3px;
line-height: 24px;
margin: 8px 0;
text-align: left;
text-size-adjust: 100%;
`;

export const Breadcrumb = styled.h3`
display: flex;
flex-direction: row;
margin: 0;
font-family: Inter, sans-serif;
& > button, & > span {
cursor: pointer;
background: none;
  padding: 0 2px;
  border: 0;
  color: rgb(167, 169, 171);
  display: block;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 19.2px;
  text-align: left;
  text-decoration-color: rgb(167, 169, 171);
}
`;

export const FoundUsers = styled.h3`
  display: flex;
  flex-direction: row;
  margin: 0;
  & > span {
  cursor: pointer;
  background: none;
    padding: 0 2px;
    border: 0;
    color: rgb(167, 169, 171);
    display: block;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.3px;
    line-height: 19.2px;
    text-align: left;
    text-decoration-color: rgb(167, 169, 171);
  }
`;

export const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  `;

export const Content = styled.div`
  width: 60%;
  `

export const Card = styled.div`
  min-height: 1px;
  padding: 1.25rem;
  position: relative;
  display: flex;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: 0.5rem;
  align-items: flex-start;
  flex-direction: column;
`;

export const Form = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  & > div {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    &.row-1 {
      width: 8.3333%;
    }
    &.row-2 {
      width: 16.6666%;
    }
    &.row-3 {
      width: 25%;
    }
    &.row-4 {
      width: 33.3333%;
    }
    &.row-5 {
      width: 16.6666%;
    }
    &.row-6 {
      width: 50%;
    }
    &.row-7 {
      width: 58.33333%;
    }
    &.row-8 {
      width: 66.6666%;
    }
    &.row-12 {
      width: 100%;
    }

    &.direction-row {
      flex-direction: row;
      align-items: center;
      & > label {
        width: initial;
        & > input {
          width: initial;
        }
      }
    }

    & > label {
      margin: 8px 0;
      width: calc(100% - 32px);
      transition: all 0.3s; 
      font-size: 14px;
      font-weight: 400;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.29;
      letter-spacing: normal;
      color: #3a4a5b;
      & > input {
        width: 100%;
        padding: 8px 0;
        font-family: Inter, sans-serif;
      }

      & > input {
        border: 0;
        border-bottom: 1px solid rgba(0,0,0,0.50);
        border-radius: 0;
        font-size: 15px;
        &:focus-visible {
          outline: none;
        }
      }

      & > small {
        display: none;
      }
      
      &.error {
        transition: all 0.3s;
        color: red;
        & > input {
          color: red;
          border-bottom: 1px solid red;
        }
        & > small {
          display: initial;
        }
      }
      &.fullwidth {
        width: 100%;
      }
    }
  }
  & h3 {
    width: calc(100% - 16px);
    margin-left: 16px;
    font-family: Inter, sans-serif;
    font-size: 20px;
    font-weight: 300;
    line-height: 26px;
    letter-spacing: 0px;
    text-align: left;
    color: #143968;
  }
`;

export const Head = styled.div`
  width: 100%;
  border-bottom: 1px solid rgba(0,0,0,0.125);
    & > button {
    border: none;
    cursor: pointer;
    background: transparent;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    padding: 16px 24px;
    margin: 0;
    color: #143968;
    text-transform: uppercase;
    &:hover {
      background-color: #eee;
    }
    &.selected {
      border-bottom: 3px solid #143968;
    }
  }
`

export const Actions = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row !important;
  margin: 16px 0 0 0;
`;

export const BtnSave = styled.button`
  border-radius: 100px;
  border: none;
  color: #FFFFFF;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
  background: #143968;
  min-width: 200px;
  padding: 12px 8px;
  max-width: 200px;
  cursor: pointer;
  `;

export const BtnCancel = styled.button`
  padding: 12px 8px;
  border-radius: 100px;
  color: #646464;
  font-family: Inter, sans-serif;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-left: 16px;
  text-transform: uppercase;
  border: none;
  min-width: 170px;
  max-width: 170px;
  background-color: #fff;
  border: 1px solid #ccc;
  transition: all 0.25s;
  &:hover {
    transition: all 0.25s;
    background-color: #eee;
  }
`;

export const Warning = styled.div`
  background-color: #EDFAFF;
  padding: 20px;
  border-radius: 8px;
  & > p {
    line-height: 1.5rem;
    margin: 0;
  }
`;

export const ImageSpot = styled.div`
  flex-direction: row !important;
  justify-content: flex-start;
  width: 100%;
  & > h3 {
    width: 100%;
  }
`;

export const ImageTreshold = styled.div`
  width: 240px;
  height: 120px;
  border: 1px dotted #aaa;
  border-radius: 4px;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-left: 24px;
  width: calc(100% - 300px);
`;

export const ImageLabel = styled.label`
  padding: 50px;
  width: 100%;
  border: 1px dotted #aaa;
  border-radius: 4px;
  text-align: center;
  transition: all 0.3s;
  color: #666;
  cursor: pointer;
  &:hover {
    transition: all 0.3s;
    background-color: #eee;
  }
`;
export const ImageInput = styled.input`
  width: 100%;
  display: none;
`;
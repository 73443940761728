/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { auth } from "./firebaseConfig";

function Logout() {
  let navigate = useNavigate();

  useEffect(() => {
    auth.signOut();

    Cookies.remove("@innovaContol_current_user");
    Cookies.remove("@innovally");
    Cookies.remove("@inovally");
    Cookies.remove("@username");
    navigate('/login');
  }, []);

  return (
    <div>
    </div>
  );
}

export default Logout;

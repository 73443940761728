import { useEffect, useState } from "react";
import * as S from "./styles";
import axios from "axios";
import { getDebtToken } from "../../services/auth";

const CityHallListing = ({ setParentValue }) => {
  const [page, setPage] = useState(0);
  const [dataTable, setDataTable] = useState([]);
  const [filter, setFilter] = useState({ name: "" });
  const [totalUsers, setTotalUsers] = useState(0);
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const search = async (pageToLoad = 0, isPagination = false) => {
    try {
      let response;
      if (filter.name) {
        response = await axios.get(
          `${process.env.REACT_APP_DEBT_API}open/cityhall/filter?name=${filter.name}`,
          {
            headers: {
              'Authorization': `Bearer ${getDebtToken()}`,
              'Content-Type': 'application/json'
            }
          }
        );
      } else {
        response = await axios.get(
          `${process.env.REACT_APP_DEBT_API}open/cityhall?page=${pageToLoad}`,
          {
            headers: {
              'Authorization': `Bearer ${getDebtToken()}`,
              'Content-Type': 'application/json'
            }
          }
        );
      }

      const data = response.data.data;
      setDataTable((prev) => isPagination ? [...prev, ...data] : data);
      setTotalUsers(response.data.totalElements);
    } catch (error) {
      console.log(error);
    }
  };

  const searchUsers = async (pageToLoad = 0, isPagination = false) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DEBT_API}user/listPageable?page=${pageToLoad}`,
        {
          headers: {
            'Authorization': `Bearer ${getDebtToken()}`,
            'Content-Type': 'application/json'
          }
        }
      );
      if (response.data.length === 0 || !response.data) {
        setShowModal(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const timeout = setTimeout(() => {
      search(0, false);
    }, 500);

    setDebounceTimeout(timeout);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    searchUsers();
  }, [])


  useEffect(() => {

    setPage(0);
    search(0);
    // eslint-disable-next-line
  }, []);

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    search(nextPage, true);
  };

  const goToEdit = (id) => {
    localStorage.setItem("cityHallId", id);
    setParentValue("cadastroPrefeitura");
  };

  return (
    <>
      <S.Header>
        <S.Content>
          <S.Breadcrumb>
            <button onClick={() => setParentValue("prefeitura")}>Home</button>
            <span>&gt;</span>
            <span>Prefeituras</span>
            <span>&gt;</span>
          </S.Breadcrumb>
          <S.Title>Listagem</S.Title>
          <S.FoundUsers>
            <span>{totalUsers} prefeituras cadastradas</span>
          </S.FoundUsers>
        </S.Content>
        <S.Actions>
          <S.AddUser onClick={() => { setParentValue("cadastroPrefeitura") }}>
            <span>+</span>Adicionar Prefeitura
          </S.AddUser>
        </S.Actions>
      </S.Header>
      <S.Card>
        <S.SearchWrapper>
          <S.CardTitle>Buscar prefeitura</S.CardTitle>
          <div>
            <S.Searchbar
              placeholder="Digite o nome"
              onInput={(e) => { setFilter((prev) => ({ ...prev, name: e.target.value })) }}
            ></S.Searchbar>
          </div>
        </S.SearchWrapper>
        <S.Table>
          <S.TableHead>
            <span className="row-4">Nome</span>
            <span className="row-4">Endereço</span>
            <span className="row-2">CNPJ</span>
            <span className="row-2">UF</span>
          </S.TableHead>
          <S.DataTable>
            {dataTable.length > 0 && dataTable.map((row, index) => {
              return (
                <S.Line key={index} onClick={() => { goToEdit(row?.cityHall?.cityId) }}>
                  <span className="row-4">
                    {row?.cityHall?.legalName}
                  </span>
                  <span className="row-4">
                    {row?.cityHall?.address}
                  </span>
                  <span className="row-2">
                    {row?.cityHall?.cnpj}
                  </span>
                  <span className="row-2">
                    {row.cityHall?.uf}
                  </span>

                </S.Line>
              );
            })}
            {dataTable.length > 0 && dataTable.length < totalUsers && <S.BtnSearch onClick={loadMore}>Carregar mais +</S.BtnSearch>}
          </S.DataTable>
          {(dataTable.length === 0) && <S.Error><h3>Não existem prefeituras.</h3></S.Error>}
        </S.Table>
      </S.Card>
      {showModal && <S.Container>
        <S.Modal>
          <S.ModalTitle>Ops...</S.ModalTitle>
          <S.ModalContent id="content">
            <div>
              <p>Por gentileza, crie um usuário antes de criar a Prefeitura!</p>
              <button onClick={() => { setShowModal(false); setParentValue("listagem") }}>Fechar</button>
            </div>
          </S.ModalContent>
        </S.Modal>
      </S.Container>}
    </>
  );
}

export default CityHallListing;
import styled from "styled-components";

export const Header = styled.div`
  top: 0;
  position: fixed;
  width: 100%;
  height: 80px;
  box-shadow: 0 1px 15px #0000000a, 0 1px 6px #0000000a;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  background: linear-gradient(270deg, #14D1AA, #0A5282);
  color: #fff;
  z-index: 100;
    & > div.right {
      & > a {
      position: relative;
      padding: 0 27px;
      &:after {
        content:"";
        position: absolute;
        right: 0;
        height: 100%;
        width: 1px;
        background-color: #70A6C0;
      }
    }
    & > img {
      justify-self: flex-start;
      margin-left: 32px;
    }
  }
`;

export const User = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-right: 32px;
  position: relative;
  & > h3 {
    font-size: 16px;
    letter-spacing: .3px;
    font-weight: 400;
    line-height: 1.6;
    color: #fff;
    font-family: Inter, sans-serif;
    position: relative;
    &:after{
      content: "";
      width: 5px;
      height: 5px;
      position: absolute;
      right: 0;
      top: 50%;
      border-left: 3px solid #fff;
      border-top: 3px solid #fff;
      transform: translate(16px, -55%) rotate(-135deg);

    }
  }
  & > img {
    margin-right: 12px;
    height: 24px;
    width: 24px;
  }
`;

export const PopUp = styled.div`
  position: absolute;
  min-width: 170px;
  top: 60px;
  right: 0;
  background: #fff;
  border-radius: 2px;
  &:after {
    position: absolute;
    top: -10px;
    right: 20px;
    display: inline-block;
    border-right: 15px solid transparent;
    border-bottom: 15px solid white;
    border-left: 15px solid transparent;
    content: "";
  }
`;

export const Line = styled.button`
  width: 100%;
  border: none;
  background-color: transparent;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: #a1a1a1;
  padding: 10px 16px;
  border-radius: 2px;
  cursor: pointer;
  &:hover {
    border-radius: 0px;
    background-color: #eee;
  }
`;